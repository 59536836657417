import { Component, OnInit, Input, forwardRef, KeyValueDiffers, Output, EventEmitter, ViewChild, ElementRef, Injector } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
	selector: 'jabil-select',
	templateUrl: './jabil-select.component.html',
	styleUrls: ['./jabil-select.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => JabilSelectComponent),
			multi: true,
		},
	],
})
export class JabilSelectComponent implements OnInit, ControlValueAccessor {
	@Input() dataSource: any[];
	@Input() tooltip_message: string = ""; 
	@Input() tooltip_position: string = "top";
	@Input() displayText: string;
	@Input() required: boolean = false;
	@Input() displayValue: string;
	@Input() isMultiple: boolean = false;
	@Input() caption: string;
	@Input() clearable: boolean = true;
	@Input() nameControl: string;
	@Input() textInstruction: string;
	@Input() useTranslate: boolean = false;
	@Input() station: string = "";
	@Output() changeValue: EventEmitter<any> = new EventEmitter();
	selectedValue: any;
	value: any;
	isDisabled: boolean;
	@Input() isReadOnly: boolean = false;
	fm = new FormControl();
	ngControl: NgControl;
	@ViewChild("select") select: NgSelectComponent;
  
  
	constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.fm.valueChanges.subscribe(v => {
      this.onChange(v);
    });
  }

  onChange = (_: any) => {}
  onTouch = () => { }

  onSelected(value: any) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
    this.changeValue.emit(this.value);

    if (this.value) {
      if (this.select.classes)
        this.select.classes.replace("customError", "");
    }
  }

  writeValue(value: any): void {
    if (value != null) {
      this.value = value || '';
      this.changeValue.emit(this.value);
    } else {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouch = fn; }
  setDisabledState(isDisabled: boolean): void { this.isDisabled = isDisabled; }
}
