import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'jabil-text-area',
  templateUrl: './jabil-text-area.component.html',
  styleUrls: ['./jabil-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JabilTextAreaComponent),
      multi: true
    }
  ]
})
export class JabilTextAreaComponent implements OnInit, ControlValueAccessor{
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() caption: string = '';
  @Input() displayText: string = '';
  @Input() placeholder: string = '';
  @Input() nameControl: string;
  @Input() numberOfRows: string = '5';
  @Input() isReadOnly: boolean = false;
  @Input() tooltip_message: string = ""; 
  @Input() tooltip_position: string = "top";
  
  value: any;
  onChange = (_: any) => { }
  onTouch = () => { }

  constructor() { }

  ngOnInit(): void {
  }

  onInput(value: any) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouch = fn; }
}