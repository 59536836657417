import { MenuIntemInterface } from './interfaces/menu-item.interface';
import { MenuCollapseInterface } from './interfaces/menu-collapse.interface';
import { MenuGroupInterface } from './interfaces/menu-group.interface';

export class AppNavigationModel {
  public model: any[];

  constructor() {
    this.model = [
		{
			id: 'applications',
			title: 'Applications',
			translate: 'Jabil Access Control System',
			type: 'group',
			icon: 'apps',
			children: [
				{
					id: 'Home',
					title: 'Home',
					translate: 'NAVBAR.Home',
					type: 'item',
					url: '/main/home',
					icon: 'home',
				},
				{
					id: 'My request',
					title: 'My request',
					translate: 'MYREQUEST.My_request',
					type: 'item',
					url: '/main/my-requests',
					icon: 'edit',
				},
				{
					id: 'My approvals',
					title: 'My Approvals',
					translate: 'MYAPPROVALS.My_approvals',
					type: 'item',
					url: '/main/my-approvals',
					icon: 'check_circle',
				},
				{
					id: 'Visitor access',
					title: 'Visitor Access',
					translate: 'NAVBAR.Visitor_access',
					type: 'item',
					url: '/public/visitor-access/0',
					icon: 'lock_open',
				},
				{
					id: 'NDAs management',
					title: 'NDAs management',
					translate: 'NDAs.NDASManagement',
					type: 'item',
					url: '/main/ndamanagement',
					icon: 'file_copy',
				},
				{
					id: 'EHS',
					title: 'EHS',
					translate: 'EHS',
					type: 'collapse',
					icon: 'health_and_safety',
					children: [
						{
							id: 'EHS exams management',
							title: 'EHS Exams Management',
							translate: 'EHSEXAMS.EHSExamsManagement',
							type: 'item',
							url: '/main/examsmanagement',
							icon: 'description',
						},
						{
							id: 'EHS exams Viewer',
							title: 'EHS Exams Viewer',
							translate: 'exam_viewer',
							type: 'item',
							url: '/public/exam-viewer',
							icon: 'content_paste_search',
						},
						{
							id: 'EHS exams result',
							title: 'EHS Exams result',
							translate: 'EHSEXAMS.EHSExamsResults',
							type: 'item',
							url: '/main/examsresults',
							icon: 'checklist_rtl',
						},
					],
				},
				{
					id: 'Catalogs',
					title: 'Catalogs',
					translate: 'NAVBAR.Catalogs',
					type: 'collapse',
					icon: 'workspaces',
					children: [
						{
							id: 'Projects',
							title: 'Projects',
							translate: 'NAVBAR.Projects',
							type: 'item',
							url: '/main/projects',
							icon: 'group_work',
						},
						{
							id: 'Areas',
							title: 'Areas',
							translate: 'NAVBAR.Areas',
							type: 'item',
							url: '/main/areas',
							icon: 'location_pin',
						},
						{
							id: 'Documents types',
							title: 'Documents types',
							translate: 'DOCUMENTSTYPES.documentstypestitle',
							type: 'item',
							url: '/main/catalogs/DocumentsTypes',
							icon: 'file_copy',
						},
						{
							id: 'Identifications types',
							title: 'Identification types',
							translate: 'CATALOG_IDENTIFICATIONSTYPES',
							type: 'item',
							url: '/main/identificationstypes',
							icon: 'badge',
						},
						{
							id: 'Equipments types',
							title: 'Equipments types',
							translate: 'CATALOG_EQUIPMENTTYPES',
							type: 'item',
							url: '/main/equipmenttypes',
							icon: 'computer',
						},
						{
							id: 'Company suppliers',
							title: 'Company suppliers',
							translate: 'CATALOGS.COMPANYSUPPLIERS',
							type: 'item',
							url: '/main/companysuppliers',
							icon: 'business_center',
						},
						{
							id: 'Activities',
							title: 'Activities',
							translate: 'MYREQUEST.Activities',
							type: 'item',
							url: '/main/activities',
							icon: 'checklist',
						},
						{
							id: 'Entrances',
							title: 'Entrances',
							translate: 'NAVBAR.entrances',
							type: 'item',
							url: '/main/entrances',
							icon: 'house',
						},
						{
							id: 'Customers',
							title: 'Customers',
							translate: 'NAVBAR.customers',
							type: 'item',
							url: '/main/clients',
							icon: 'person',
						},
					],
				},
				{
					id: 'Sites & buildings',
					title: 'Sites & buildings',
					translate: 'NAVBAR.Sites_and_buildings',
					type: 'collapse',
					icon: 'apartment',
					children: [
						{
							id: 'Sites',
							title: 'Sites',
							translate: 'SITES.sitetitle',
							type: 'item',
							url: '/main/catalogs/Sites',
							icon: 'business',
						},
						{
							id: 'Buildings',
							title: 'Buildings',
							translate: 'BUILDINGS.buildingstitle',
							type: 'item',
							url: '/main/catalogs/Buildings',
							icon: 'factory',
						},
					],
				},
				{
					id: 'Employees & roles',
					title: 'Users & Roles',
					translate: 'NAVBAR.Users_and_roles',
					type: 'collapse',
					icon: 'people_alt',
					children: [
						{
							id: 'Users',
							title: 'Users',
							translate: 'EMPLOYEES.Employees',
							type: 'item',
							url: '/main/employees',
							icon: 'person',
						},
						{
							id: 'Roles',
							title: 'Roles',
							translate: 'ROLES.Roles',
							type: 'item',
							url: '/main/roles',
							icon: 'lock_person',
						},
					],
				},
				{
					id: 'Configurations',
					title: 'Configurations',
					translate: 'NAVBAR.Configurations',
					type: 'collapse',
					icon: 'settings',
					children: [
						{
							id: 'Sites configurations',
							title: 'Sites configurations',
							translate: 'SITES.SitesConfigurations',
							type: 'item',
							url: '/main/siteconfiguration',
							icon: 'miscellaneous_services',
						},
						{
							id: 'Printer configurations',
							title: 'Printer configurations',
							translate: 'PRINTERCONFIGURATIONS.Printer_configurations_catalog',
							type: 'item',
							url: '/main/printerconfigurations',
							icon: 'print',
						},
					],
				},
				{
					id: 'Reports',
					title: 'Reports',
					translate: 'NAVBAR.Reports',
					type: 'item',
					url: '/main/reports',
					icon: 'pie_chart',
				},
				{
					id: 'About',
					title: 'About',
					translate: 'NAVBAR.About',
					type: 'collapse',
					icon: 'help',
					children: [
						{
							id: 'Contact',
							title: 'Contact',
							translate: 'CONTACT.Contact_title',
							type: 'item',
							url: '/main/contact',
							icon: 'phone',
						},
						{
							id: 'Releases',
							title: 'Last release',
							translate: 'Last_release',
							type: 'item',
							url: '/main/lastrelease',
							icon: 'refresh',
						},
					],
				},
			],
		},
	];
  }
}

