import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IScreens } from '../../../main/content/sections/Interfaces/IScreens';
import { RolesService } from '../../../main/content/sections/services/roles-service';
import { TransactionResult } from '../../../core/services/interfaces/transaction-result';

@Injectable()
export class NavigationService {
	onNavCollapseToggle = new EventEmitter<any>();
	onNavCollapseToggled = new EventEmitter<any>();
	onNavigationModelChange: BehaviorSubject<any> = new BehaviorSubject({});
	navigationModel: { model: any[] };
	flatNavigation: any[] = [];
	listScreens: IScreens[];

	constructor(private roleService: RolesService) {}

	/**
	 * Get navigation model
	 *
	 * @returns {any[]}
	 */
	getNavigationModel() {
		return this.navigationModel.model;
	}

	/**
	 * Set the navigation model
	 *
	 * @param model
	 */
	setNavigationModel(model) {
        let site = localStorage.getItem('pksite');
		if (!window.location.href.includes('public') && (site != null && site != 'null')){
			this.roleService.GetScreensByUsername().subscribe(
				(result: TransactionResult<IScreens[]>) => {
					if (result.success) {
						
						this.listScreens = result.data;
						var i: number = 0;
						var j: number = 0;
						this.listScreens.forEach((element) => {
							if (element.available == false) {
								i = 0;
								model.model[0].children.forEach(
									(elementModel) => {
										if (
											model.model[0].children[i].children
										) {
											j = 0;
											j = setNvigationModelConditions(
												j,
												i,
												element,
											);
										}
										if (
											elementModel.id ==
											element.screen
										) {
											model.model[0].children.splice(
												model.model[0].children.indexOf(
													elementModel,
												),
												1,
											);
										}
										i = i + 1;
									},
								);
							}
						});

						this.navigationModel = model;
						this.onNavigationModelChange.next(
							this.navigationModel.model,
						);
					}
				},
				(error) => {},
			);
		}
    

		function setNvigationModelConditions(
			j: number,
			i: number,
			element: IScreens,
		) {
			model.model[0].children[i].children.forEach((level2) => {
				if (model.model[0].children[i].children[j].children) {
					model.model[0].children[i].children[j].children.forEach(
						(level3) => {
							if (level3.id == element.screen) {
								model.model[0].children[i].children[
									j
								].children.splice(
									model.model[0].children[i].children[
										j
									].children.indexOf(level3),
									1,
								);
							}
						},
					);
				}
				if (level2.id == element.screen) {
					model.model[0].children[i].children.splice(
						model.model[0].children[i].children.indexOf(level2),
						1,
					);
				}
				j = j + 1;
			});
			return j;
		}
	}

	/*
	 * Function to refract the setNavigationModel function
	 */

	/**
	 * Add new navigation item
	 * to the given location
	 */
	addNavigationItem(location, item) {
		// Parse the location
		const locationArr = location.split('.');

		if (locationArr.length === 0) {
			return;
		}

		// Find the navigation item
		const navItem = this.findNavigationItemById(locationArr);

		// Act according to the item type
		switch (navItem.type) {
			case 'item':
				// Create a children array
				navItem.children = [];

				// Push the item
				navItem.children.push(item);

				// Change the item type to collapsable
				navItem.type = 'collapse';

				break;

			case 'collapse':
				// Push the item
				navItem.children.push(item);

				break;

			case 'group':
				// Push the item
				navItem.children.push(item);

				break;

			default:
				break;
		}
	}

	/**
	 * Get navigation item from
	 * given location
	 *
	 * @param location
	 */
	getNavigationItem(location) {
		// Parse the location
		const locationArr = location.split('.');

		if (locationArr.length === 0) {
			return;
		}

		// Find and return the navigation item
		return this.findNavigationItemById(locationArr);
	}

	/**
	 * Find navigation item by location
	 *
	 * @param location
	 * @param navigation
	 */
	findNavigationItemById(location, navigation?) {
		if (!navigation) {
			navigation = this.navigationModel.model;
		}

		// Iterate through the given navigation
		for (const navItem of navigation) {
			// If the nav item id equals the first location...
			if (navItem.id === location[0]) {
				// If there is more location to look at...
				if (location.length > 1) {
					// Remove the first item of the location
					location.splice(0, 1);

					// Go nested...
					return this.findNavigationItemById(
						location,
						navItem.children,
					);
				}

				// Otherwise just return the nav item
				else {
					return navItem;
				}
			}
		}
	}

	/**
	 * Get flattened navigation array
	 * @param navigationItems
	 * @returns {any[]}
	 */
	getFlatNavigation(navigationItems?) {
		// If navigation items not provided,
		// that means we are running the function
		// for the first time...
		if (!navigationItems) {
			// Reset the flat navigation
			this.flatNavigation = [];

			// Get the entire navigation model
			navigationItems = this.navigationModel.model;
		}

		for (const navItem of navigationItems) {
			if (navItem.type === 'subheader') {
				continue;
			}

			if (navItem.type === 'item') {
				this.flatNavigation.push({
					title: navItem.title,
					type: navItem.type,
					icon: navItem.icon || false,
					url: navItem.url,
				});

				continue;
			}

			if (navItem.type === 'collapse' || navItem.type === 'group') {
				if (navItem.children) {
					this.getFlatNavigation(navItem.children);
				}
			}
		}

		return this.flatNavigation;
	}
}
