import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { TranslateApplicationService } from '../../core/translate/translate-application-service';
import { CognitoService } from '../../core/services/cognito/cognito.service';
import { NavigationService } from 'src/app/core/components/navigation/navigation.service';
import { AppNavigationModel } from '../../navigation/navigation.model';
import { ISites } from '../content/sections/Interfaces/ISites';
import { SitesService } from '../content/sections/services/sites-services';
import { RolesService } from '../content/sections/services/roles-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Language } from '../../core/translate/models/language';
import { NotificationService } from '../../core/services/notification/notification.service';
import { IRoles } from '../content/sections/Interfaces/IRoles';

declare var jQuery: any;

@Component({
	selector: 'app-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss'],
})
export class AppToolbarComponent {
	userStatusOptions: any[];
	languages: Array<any> = [];
	selectedLanguage: any;
	showLoadingBar: boolean;
	horizontalNav: boolean;
	username: string;
	isAuthenticated: boolean;
	showSite: boolean;
	formGroup: FormGroup;
	listSites: Array<any> = [];

	constructor(
		private router: Router,
		private appConfig: AppConfigService,
		private translate: TranslateApplicationService,
		private notificationService: NotificationService,
		private cognitoService: CognitoService,
		private appNavigationService: NavigationService,
		private applications: ApplicationInfoService,
		private siteService: SitesService,
		private rolesService: RolesService,
		private formBuilder: FormBuilder,
	) {
		this.isAuthenticated = false;
		this.username = '';
		this.formGroup = this.formBuilder.group({
			fksite: [null, Validators.required],
		});

		this.languages = applications.languages;

		if (this.languages.length == 0) {
			this.languages.push({
				id: 1,
				title: 'English',
				flag: 'en',
				applicationId: 0,
			});

			this.languages.push({
				id: 1,
				title: 'Spanish',
				flag: 'es',
				applicationId: 0,
			});
		}

		this.SelectLanguage();

		router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.showLoadingBar = true;
			}
			if (event instanceof NavigationEnd) {
				this.showLoadingBar = false;
			}
		});

		this.appConfig.onSettingsChanged.subscribe((settings) => {
			this.horizontalNav = settings.layout.navigation === 'top';
		});

		this.LocationPublic();
	}

	LocationPublic() {
		if (window.location.href.includes('public')) {
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
			);
		} else {
			this.ChooseEnviroment();
		}
	}

	ChooseEnviroment() {
		if (this.cognitoService.IsAuhtenticated()) {
			this.cognitoService.GetUserInfo().subscribe((result) => {
				this.username = result['email'];
				let isDev = window['configuration'].isDev;
				let userNT = '';
				if (isDev) {
					//DEV
					userNT = result['cognito:username'];
					userNT = userNT.slice(5);
				} else {
					// STG | PRD
					userNT = result['custom:samaccountname'];
				}


				var email = result['email'];
				localStorage.setItem('email', email);
				this.appNavigationService.setNavigationModel(
					new AppNavigationModel(),
        );
        
        
		this.GetSites();
			});
		}
	}

	SelectLanguage(): void {
		let indexLang = 0;
		if (this.languages != undefined) {
			if (navigator.language.split('-').length > 0) {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language.split('-')[0],
				);
			} else {
				indexLang = this.languages.findIndex(
					(lang) => lang.flag === navigator.language,
				);
			}

			if (indexLang < 0) {
				this.selectedLanguage = this.languages[0];
			} else {
				this.selectedLanguage = this.languages[indexLang];
			}

			this.SetLanguage(this.selectedLanguage);
		}
	}

	SetLanguage(lang) {
		// Set the selected language for toolbar
		this.selectedLanguage = lang;
		// selected language for translations
		this.translate.use(lang);
	}

	GetRol(){
		this.rolesService.GetRoleByUser().subscribe(
			(result: TransactionResult<IRoles>) => {
				
				if(result){
					localStorage.setItem('role', result['role']);
				}	
			}
		);
	}


	GetSites() {
		this.listSites = [];
		this.showSite = false;
		let first = true;
		let fksite = window.localStorage.getItem('pksite');
		this.siteService.GetSitesByUsername().subscribe(
			(result: TransactionResult<ISites[]>) => {
        if (result.success) {
          if (result.data.length > 0) {
	        result.data.forEach((obj) => {
              if (first && fksite == undefined) {
                localStorage.setItem(
                  'pksite',
                  obj.pksite.toString(),
                );
                this.formGroup.controls.fksite.setValue(obj.pksite);
                this.showSite = true;
              }
              if (obj.pksite.toString() == fksite) {
                this.formGroup.controls.fksite.setValue(obj.pksite);
                this.showSite = true;
              }
              first = false;
              this.listSites.push(obj);
			});
			this.GetRol();
            this.appNavigationService.setNavigationModel(
              new AppNavigationModel(),
            );
          } else {
            this.router.navigateByUrl('/main/without-permission');
          }
				}
			},
			(error: any) => { this.ErrorAlert(error); },
		);
	}

	ChangeSite() {
		if (this.cognitoService.IsAuhtenticated()) {
			localStorage.setItem(
				'pksite',
				this.formGroup.controls.fksite.value,
			);
			jQuery('.btnReload').trigger('click');
			/*La siguiente linea crea todo el menu */
			this.appNavigationService.setNavigationModel(
				new AppNavigationModel(),
      );
		}
	}

	LogOut(): void {
		this.cognitoService.LogOut();
	}

	ErrorAlert(message: any) {
		this.notificationService.AddAlert({
		  icon: 'all_inbox',
		  title: 'Error',
		  message: message,
		  shown: true,
		});
	  }
}
