import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { ISites } from '../Interfaces/ISites';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { CognitoService } from 'src/app/core/services/cognito/cognito.service';
import { ISitesConfigurations } from '../Interfaces/ISitesConfigurations';
import { ILanguages } from '../Interfaces/ILanguages';

@Injectable({
	providedIn: 'root',
})
export class SitesService extends RestCallService {
	public dataToSend: ISites = {
		pksite: 0,
		site: '',
		code: '',
		companyname: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		available: true,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
		fkemployee: 0,
		check: false,
		building: '',
		fkbuilding: 0,
		level: 0,
		fksite: 0,
		pkbuilding: 0,
		fksiteloggeduser: 0,
    timezone: '',
    fklanguage: 0,
	};
	public dataSitesConfigurations: ISitesConfigurations = {
		pksiteconfiguration: 0,
		configuration: '',
		description: '',
		fkvalue: 0,
		value: '',
		fksite: 0,
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 0,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 0,
    username: '',
    pk_site_configuration_value: 0,
    site_configuration_value: ''
  };
  
  public languagesDataSend: ILanguages = {
  pklanguage: 0,
  language: '',
  available: false,
  username: '',
  fksite: 0
}

	constructor(
		public httpClient: HttpClient,
		private cognitoService: CognitoService,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(httpClient, 'JACSapi', toastr, notificationService, translate);
	}

	public GetList() {
		this.dataToSend.fksite = parseInt(localStorage.getItem('pksite'));
		return this.post<ISites[]>(
			'/api/Sites/GetSites',
			this.dataToSend
		);
	}

	public GetSitesBuildingsByUsername(data: string) {
		this.dataToSend.username = data;
		this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
		return this.post(
			'/api/Sites/GetSitesBuildingsByUsername',
			this.dataToSend,
		);
	}

	public GetSitesByUsername() {
		this.dataToSend.fksite = localStorage.getItem('pksite') ? Number(localStorage.getItem('pksite')) : 0;
		return this.post<ISites[]>( '/api/Sites/GetSitesByUsername', this.dataToSend );
	}

	public CreateSite(data: any) {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();
		this.dataToSend.site = data.txtsite.trim();
		this.dataToSend.code = data.txtcode.trim();
		this.dataToSend.companyname = data.txtcompanyname.trim();
		this.dataToSend.fksiteloggeduser = parseInt(localStorage.getItem('pksite'));
    	this.dataToSend.timezone = data.cbtimezone.trim();
    	this.dataToSend.fklanguage = data.cbfklanguage;
		return this.post<ISites[]>('/api/Sites/CreateSite', this.dataToSend);
	}

	public UpdateSite(data: any) {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();
		this.dataToSend.pksite = data.pksite;
		this.dataToSend.site = data.txtsite.trim();
		this.dataToSend.code = data.txtcode.trim();
    	this.dataToSend.companyname = data.txtcompanyname.trim();
    	this.dataToSend.timezone = data.cbtimezone.trim();
    	this.dataToSend.fklanguage = data.cbfklanguage;
		this.dataToSend.fksiteloggeduser = parseInt(localStorage.getItem('pksite'));
		this.dataToSend.available = data.available;
		return this.put('/api/Sites/UpdateSite', this.dataToSend);
  }
  
  public GetSiteByName(data: any) {
    if (!this.cognitoService.IsAuhtenticated() && !this.cognitoService.GetUserInfo()) {
		  this.cognitoService.LogOut();
    }

    this.dataToSend.fksite = Number(window.localStorage.getItem('pksite'));
    this.dataToSend.site = data.txtsite;
    return this.post<ISites[]>('/api/Sites/GetSiteByName', this.dataToSend);
  }

	public ChangeSiteStatus(data: any) {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();
    this.dataToSend.pksite = data.pksite;
    this.dataToSend.site = data.txtsite;
    this.dataToSend.code = data.txtcode;
    this.dataToSend.companyname = data.txtcompanyname;
    this.dataToSend.timezone = data.cbtimezone;
    this.dataToSend.fklanguage = data.cbfklanguage;
		this.dataToSend.available = data.available;
		this.dataToSend.fksiteloggeduser = parseInt(
			localStorage.getItem('pksite'),
		);
		return this.put('/api/Sites/ChangeSiteStatus', this.dataToSend);
	}

	public GetSitesConfigurations() {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();

		this.dataSitesConfigurations.fksite = Number(window.localStorage.getItem('pksite'));

		return this.post<ISitesConfigurations[]>(
			'/api/SitesConfigurations/GetSitesConfigurations',
			this.dataSitesConfigurations,
		);
  }
  
  public GetValuesBySitesConfigurations(data: string) {
    if (!this.cognitoService.IsAuhtenticated() && !this.cognitoService.GetUserInfo()) {
			this.cognitoService.LogOut();
    }

    this.dataSitesConfigurations.configuration = data;
		this.dataSitesConfigurations.fksite = Number(window.localStorage.getItem('pksite'));

		return this.post<ISitesConfigurations[]>(
			'/api/SitesConfigurations/GetValuesBySitesConfigurations',
			this.dataSitesConfigurations,
		);
  }
  
  public GetValueOfConfiguration(data: any) {
    if (!this.cognitoService.IsAuhtenticated() && !this.cognitoService.GetUserInfo()) {
			this.cognitoService.LogOut();
    }

    this.dataSitesConfigurations.configuration = data;
		this.dataSitesConfigurations.fksite = Number(window.localStorage.getItem('pksite'));

    return this.post<ISitesConfigurations[]>(
			'/api/SitesConfigurations/GetValueOfConfiguration',
			this.dataSitesConfigurations,
		);
  }

	public UpdateSiteConfiguration(data: any) {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();

		this.dataSitesConfigurations.fksite = Number(
			window.localStorage.getItem('pksite'),
		);
		this.dataSitesConfigurations.pksiteconfiguration =
			data.pksiteconfiguration;
		this.dataSitesConfigurations.value = data.cbvalue;

		return this.put<boolean>(
			'/api/SitesConfigurations/UpdateSiteConfiguration',
			this.dataSitesConfigurations,
		);
	}

	public GetTimeZones() {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();
		this.dataToSend.fksite = parseInt(localStorage.getItem('pksite'));

		return this.post<any[]>('/api/Sites/GetTimeZones', this.dataToSend);
	}

	public GetLanguages() {
		if (
			!this.cognitoService.IsAuhtenticated() &&
			!this.cognitoService.GetUserInfo()
		)
			this.cognitoService.LogOut();
		this.languagesDataSend.fksite = parseInt(
			localStorage.getItem('pksite'),
		);

		return this.post<any[]>(
			'/api/Sites/GetLanguages',
			this.languagesDataSend,
		);
  }
  
  public GetAvailableSites() {
   if (!this.cognitoService.IsAuhtenticated() && !this.cognitoService.GetUserInfo()) {
     this.cognitoService.LogOut(); 
    }
    this.dataToSend.fksite = Number(localStorage.getItem('pksite'));

    return this.post<any[]>(
			'/api/Sites/GetAvailableSites',
			this.dataToSend,
		);
  }
}
