import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { IProperties } from '../Interfaces/IProperties';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { ILastReleases } from '../Interfaces/ILastReleases';

@Injectable({
	providedIn: 'root',
})
export class PropertiesService extends RestCallService {
	public dataToSend: IProperties = {
		pkproperty: 0,
		property: '',
		propertyvalue: '',
		description: '',
		creationdate: new Date(),
		strcreationdate: '',
		fkemployeecreator: 1,
		lastupdated: new Date(),
		strlastupdated: '',
		fkemployeeupdater: 1,
		fkrecordstatus: 1,
		employeecreatorname: '',
		employeeupdatername: '',
		username: '',
    recordstatusname: '',
    fksite: 0
	};

	constructor(
		public httpClient: HttpClient,
		public toastr: ToastrService,
		public notificationService: NotificationService,
		public translate: TranslateApplicationService,
	) {
		super(httpClient, 'JACSapi', toastr, notificationService, translate);
	}
  
  public GetPropertyAppVersion() {
		return this.post<IProperties[]>(
			'/api/Properties/GetPropertyAppVersion',
			this.dataToSend
		);
	}

	public GetPropertyByName(data: string) {
    this.dataToSend.property = data;
		this.dataToSend.fksite = localStorage.getItem('pksite') == null ? 3 : parseInt(localStorage.getItem('pksite'));
		return this.post<IProperties[]>(
			'/api/Properties/GetPropertyByName',
			this.dataToSend,
		);
	}

	public GetLastReleases() {
		return this.post<ILastReleases[]>('/api/Properties/GetLastReleases');
	}
}
